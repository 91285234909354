<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="flex justify-center py-10 col-md-12">
                    <div class="flex flex-col text-center">
                        <h1 class="text-2xl">{{ $t('otherApps') }}</h1>
                        <p class="italic">{{ $t('optimizeWork') }}</p>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div v-for="(app, index) in apps" :key="index" class="flex flex-col md:flex-row items-center gap-6 bg-white border rounded-md p-2 mb-3">
                        <div class="flex-none flex flex-col text-center">
                            <span class="font-semibold text-lg">{{ app.name }}</span>
                            <span class="block w-24">
                                <img class="w-full" :src="app.src" :alt="app.name">
                            </span>
                        </div>
                        <div class="flex-1 w-full">
                            <span class="text-xs">{{ app.description }}</span>
                        </div>
                        <div class="flex-none flex flex-col text-center">
                            <a target="_blank" class="rounded-md p-2 bg-blue-800 no-deco text-white whitespace-nowrap uppercase" :href="app.link">{{ $t('readMore') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { OTHER_SERVICES } from './helper'


    export default {
        name : 'other-services',
        data () {
            return {
                tab : 1,
                fileAdded : false,
                apps : OTHER_SERVICES
            }
        },
        computed : {
            ...mapGetters({
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG',
            })
        },
        mounted () {
            
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.sticky-table{
    position: -webkit-sticky;
    position: sticky;
    top: 61px;
    background-color: #fff;
    // background-color: #e8eaec;
    height: 50px;
    padding: 7px;
}
.card-body{
    padding: 0 0.9rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.body-tabs-shadow .body-tabs-animated{
    padding: unset !important;
}
.tab-btn.active{
    box-shadow: unset !important;
    border: 1px solid $theme-secondary-color !important;
    font-weight: 800;
    color: $theme-secondary-color !important;
    border-radius: 5px;
}
.tab-btn{
    color: $theme-secondary-light;
}
.remove-bg{
    background: unset !important;
    background-color: unset !important;
}
.test-btn{
    background-color: #EE4D81;
}
.purple{
    background-color: #9B51E0;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    // border: 1px solid #ccc;
    color: white;
    border-radius: 5px;
    background-color: $theme-primary-color;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
</style>