import likvio from './../../../assets/images/services/likvio.png'
import b2b from './../../../assets/images/services/b2b.png'
import auto from './../../../assets/images/services/autofak.png'
import duplio from './../../../assets/images/services/duplio.png'

export const OTHER_SERVICES = [
    {
        name : 'Likvio',
        description : 'Likvio automatiserar och visualiserar delar av den återkommande faktureringen.\
                Likvio skapar och skickar avtalsfakturor, förnyar utgångna avtal samt skickar dig notiser om förfallna och obetalda fakturor\
                Segmentera dina kunder och artiklar för en bättre överblick och hantering och håll koll på bolagets ekonomiska hälsa med Likvio statistik modul.',
        src : likvio,
        link : 'https://www.likvio.se/'
    },
    {
        name : 'B2B Portal',
        description :'B2B portal är en molnbaserad storefront för grossister som snabbt och enkelt vill sälja till företagskunder och återförsäljare, utan att behöva sätta upp en ny webbshop.\
                B2B Portal speglar kund och artikelregistret från Fortnox, är direktintegrerad med Fortnox Finans, stödjer stafflade kundprislistor, kundegmentering och andra smarta funktioner.',
        src : b2b,
        link : 'https://b2bportal.se/'
    },
    {
        name : 'AutoFaktura',
        description : 'AutoFaktura är den perfekta appen för bolag som använder Fortnox fakturering, och har kundfakturor som ligger icke-bokförda.\
                AutoFaktura låter er styra vilka fakturor som bokförs, vilka som skickas direkt till kund och vilka som skickas via Fortnox Fakturaservice.',
        src : auto,
        link : 'https://autofaktura.com/'
    },
    {
        name : 'Duplio',
        description : 'Identifiera & hantera dubbletter i Fortnox i realtid per automatik.\
                Duplio identifierar, notifierar och hanterar dubbletter av verifikat, kundfaktura och leverantörsfaktura.',
        src : duplio,
        link : 'https://duplio.se/'
    }
]

export default {
    OTHER_SERVICES
}